div.Container{
    align-self: flex-start;
    flex: 1;
    justify-self: stretch;
    /* height: 90vh; */
    /* justify-content: center; */

    div.Cols_3 {
        display: flex;
        flex-flow: row-reverse wrap;
        min-width: 90%;
        text-align: center;
        justify-content: space-between;
        height: 77vh;
    }
      
    div.Cols_2 {
        display: flex;
        flex-flow: row-reverse wrap;
        min-width: 90%;
        text-align: center;
        justify-content: flex-end;
        height: 77vh;
    }
    
    div.FullScreen {
        display: flex;
        flex-flow: row-reverse wrap;
        min-width: 90%;
        text-align: center;
        justify-content: flex-end;
        height: 77vh;
    }
}