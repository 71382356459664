div.cancleBtn{
    color: #000;
    font-size: 30px;
    cursor: pointer;
    position: absolute;
    left: 0px;
    top: 20px;
    background-color: var(--white);
    border-radius: 50%;
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 55px;
}

div.welcomeImageDiv {
    width: 100%;
    height: 100%;
}

img.welcomeImage {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}
