.Container{
  /* align-self: center; */
  justify-self: right;
  flex-flow: column nowrap;
  height: 100%;
  /* max-height: calc(100vh - 6.3%); */
  /* max-height: 600px; */
  margin-top: 5px;
  /* flex: 1; */
  /* overflow-y: hidden; */
  /* white-space: nowrap; */
  /* border-radius: 0 var(--brdRadius) var(--brdRadius) 0; */
  background-color: var(--mainColor);
  position: relative;
  width: 100px;
  padding-top: 5px;
  padding-bottom: 5px;
  
  .ItemList {
    text-align: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: normal;
    max-width: 100px;
    /* //padding: 20px; */
    /* //margin: 0 5px; */
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }

}


/* .TopDiv button {
  background-color: transparent;
  color: var(--mainWhite);
  border: 0px;
} */


@media screen and (max-width: 850px) {
  .Container{
    position: fixed;
    bottom: 0;
    width: 100vw;
    /* border-radius: 8px 8px 0 0; */
    /* background-color: var(--mainColor); */
    display: flex;
    justify-content: center;
    /* overflow-x: auto;
    overflow-y: hidden; */
    height: 100px;
    margin-top: 0;
    border-radius:0;
    
    .ItemList {
      //margin-top: 5px;
      /* display: flex; */
      flex-flow: row nowrap;
      width: 100vw;
      max-width: 100vw;
      /* gap: 15px; */
      scrollbar-height: 3px;
      overflow-y: hidden;
      overflow-x: auto;
    }

    div.ItemLIst > *{
      padding-left: 12px;
    }

    div.ItemList:nth-child(1){
      padding-right: 12px;
    }

    div.ItemList::-webkit-scrollbar{
      height: 3px;
    }
  }


}


@media (max-height: 300px) {
  div.Container{
    height: 0;
  }
}