div.Container{
    text-align: center;
    margin: 0;
    padding: 0;

    div.CancleXBtn{
        text-align: left;
        
        span{
            font-size: 22px;
        }

        span:hover{
            cursor: pointer;
        }
    }
    
    div.TextContnet{
        div.Title{
            span{
                font-weight: 550;
                font-size: 23px;
            }
        }

        div.TowRowText{
            margin-top: 15px;
            display: flex;
            flex-direction: column;
            color: #808080;
            font-size: 19px;
            font-weight: 500;
        }
    }
    
    div.ContinueBtn{
        display: flex;
        justify-content: center;
        margin-top: 35px;

        span{
            border: 1px solid #808080;
            border-radius: 20px;
            width: 55%;
        }

        span:hover{
            cursor: pointer;
        }
    }
}