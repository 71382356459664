* {
  /* direction: rtl; */
  box-sizing: border-box;
}

:root {
  --mainColor: gray;
  --mainColor065: gray;
  --mainWhite: white;
  --mainBg: white;
  --thinScrBg: gray;
  --brdRadius: 15px;
  --mainCol1: #F1A103;
  --blue: #1e90ff;
  --white: #ffffff;
  --black: black;
  --mainWhiteOrBlack: white;
  --mainMainOrBlack: black;
}

::-webkit-scrollbar {
  width: 2px;
}
/* ::-webkit-scrollbar-track {
  background: #f1f1f1;
} */
::-webkit-scrollbar-thumb {
  background: var(--mainColor);
}
::-webkit-scrollbar-thumb:hover {
  background: var(--mainWhite);
}

img {
  max-width: 100%;
  max-height: 100%;
}

.ReactModalPortal.gray_images {
  -webkit-filter: none;
  filter: none;
}

body.gray_images {
  overflow: hidden;
}