.ModalContainer {
    position: fixed;
    top: 0;
    left: 0;
    /* z-index: 9999; */
    width: 100%;
    height: 100%;
    display: flex;
    
    /* background-color: rgba(0, 0, 0, 0.5); */
    .CancelXBtn {
        position: absolute;
        top: 20px;
        right: 60px;
        width: 30px;
        height: 30px;
        background-color: #fff;
        border-radius: 50%;
        text-align: center;
        line-height: 30px;
        cursor: pointer;
        z-index: 100;
    }
    

    .Content{
        margin-bottom: 0;
        flex: 1;

        .CourierInfo{
            /* background-color: var(--mainColor); */
            position: absolute;
            top: 5vh;
            text-align: center;
            width: 40%;
            left: 50%;
            transform: translateX(-50%);
            z-index: 100;
            /* padding-top: 1px; */
            /* display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center; */
    
        }
        .ProgressBar {
            position: absolute;
            bottom: 0;
            width: max(40%, 320px);
            left: 50%;
            transform: translateX(-50%);
            background-color: var(--mainWhite);
            border-radius: 30px;
            z-index: 100;
            box-shadow: 5px 5px 5px 0px #CECCC6;
            h1{
                margin-inline-start: 15%;
                font-size: 'max(2vw, 18px)';
            }

            /* .Milestones:last-child:before {
                    content: 'W';
                } */

            .Milestones {
                width: max(70%, 250px);
                margin-left: auto;
                margin-right: auto;
                .Milestone {
                    width: 100%;
                    /* overflow: hidden; */
                    white-space: nowrap;
                   
                    .Checkbox {
                        font-size: 25px;
                        margin-inline-end: 10px;
                        margin-inlien-start: 1px;
                        position: relative;
                        top: 10px;
                    }   
                }
                .Milestone:not(:last-child)::before {
                    content: '|';
                    position: relative;
                    opacity: 0.4;   
                    top: 24px;
                    right: 14px;
                }
                .Milestone:last-child {
                    margin: 0 3px 0 0;
                }
                .Pending {
                    color: grey;
                }
                .Active {
                    color: var(--mainColor);
                }
                .Completed {
                    color: black;
                }
            }
           
        }
    }    
}

@media (max-width: 850px) {
    .ModalContainer {
        .Content {
            .CourierInfo {
                width: 80%;
            }
            .ProgressBar {
                width: 80%;
                .Milestones {
                    width: 93%;
                }
            }
        }
    }
}
