.container{
  min-height: 80vh;
  display: flex;
  justify-content: space-around;
}
.TopDiv {
    text-align: center;
    direction: ltr !important;
    font-size: 2em;
    color: white;
    background-color: red;
    align-self: center;
    padding: 15px;
    border-radius: var(--brdRadius);
}

@media screen and (max-width: 600px) {
    .TopDiv {
      display: flex;
      flex-flow: column-reverse wrap;
    }
  }
  