
div.Container{
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #cc0000;

    /* border: 3px solid red; */
    /* height: 100%;
    width: 100%; */
    position: relative;
    div.cancleBtn{
        margin-left: 2%;
        color: #FFf;
        font-size: 30px;
        cursor: pointer;
        text-align: left;
    }

    div.Slider{
        /* border: 3px solid white; */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 60vh;
        
        div.RightArrow{
            /* border: 2px solid red; */
            display: flex;
            justify-content: center;
            align-items:center;
            height: 100%;
            font-size: 50px;
            margin: auto;
            padding-right: 5%;
            padding-left: 2%;
            color: white;
            cursor: pointer;
        }

        div.Images{
            /* border: 3px solid red; */
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            /* align-items:center; */
            overflow: auto;
        }
        
        div.LeftArrow{
            /* border: 1px solid red; */
            display: flex;
            justify-content: center;
            align-items:center;
            height: 100%;
            width: max-content;
            font-size: 50px;
            padding-left: 5%;
            padding-right: 2%;
            color: white;
            cursor: pointer;
            draggable: false;
        }
    }

    div.Dots{
        /* border: 3px solid green; */
        margin-top: 3%;
        display: flex;
        justify-content: center;
        overflow: hidden;

        div.Dot{
            padding: 8px;
            border-radius: 100%;
        }
    }

    div.Dots > * + * {
        margin-right: 2%;
    }
}
