.container{
  /* align-self: center;
  justify-self: center; */
  /* margin: auto;
  margin-top: 5%; */
  flex: 3 0 100%;
  /* width: 100vw; */
  .TopDiv {
    text-align: center;
    /* display: flex; */
    /* flex-flow: column nowrap; */
    /* justify-content: space-around; */
    margin: 5px;
    .Desc {
      color: var(--mainWhite);
      opacity: .9;
      font-size: 1.5em;
      margin-bottom: 20px;
      /* display: flex; */
      /* flex-flow: column nowrap; */
    }
    
    button{
      opacity: .95;
      border-radius: 15px;
      padding: 10px 0 10px 0;
      color: var(--mainColor);
      background-color: transparent;
      cursor: pointer;
      border: 2px solid var(--mainCol1);
      div{
        padding: 5px 15px 5px 15px;
        font-size: 0.4em;
        margin-bottom: 10px;
      }
    }
    
    button:last-child{ 
      color: var(--mainWhite);
      font-size: 120px;
    }  
  }
}

/* @media screen and (max-width: 600px) {
    .TopDiv {
      display: flex;
      flex-flow: column-reverse wrap;
    }
  }
   */