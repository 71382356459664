div.Container {
    /* border: 1px solid red; */
    /* max-height: 100%; */
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    margin-bottom: 5rem;
    height: 83vh;  


    .WhiteScreen {
      position: absolute;
      inset: 50% auto auto 50%;
      border: 1px solid rgb(204, 204, 204);
      background: rgba(255, 255, 255, 0.5);
      overflow: auto;
      border-radius: 4px;
      outline: none;
      padding: 20px;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    div.PriceInfo{
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: var(--mainWhiteOrBlack);
      border-radius: 10px;
      padding: 15px;
      text-align: center;
      width: 50%;
      background-color: var(--mainColor);

      div.PriceTitle{
        font-size: 28px;
        font-weight: 500;
      }

      div.PriceDescription{
        font-size: 15px;
      }
    }

    .PriceInfoBox {
      color: #FFFFFF;
      font: normal normal bold 66px/90px Heebo;
      margin-bottom: 0;
    }
}
  
  /* @media screen and (max-width: 600px) {
      div.PPolicy {
        width: 90vw;
        display: flex;
        flex-flow: column-reverse wrap;
      }
  } */
  
  @media (max-width: 850px){
    div.Container {
      margin-bottom: 0rem;
      height: calc((var(--vh, 1vh) * 100) - 100px);
      padding-bottom: 0;
      .PriceInfoBox  {
        font: normal normal bold 32px / 40px Heebo;
        height: var(--PriceInfoHeight);
        align-items: center;
        display: flex;
      }
      div.PriceInfo{
        width: 96%;
        div.PriceTitle{
          font-size: 20px;
          font-weight: 500;
        }
  
        div.PriceDescription{
          font-size: 10px;
        }
      }
    }
  }