div.container {
  display: flex;
    height: calc((var(--vh, 1vh) * 100) - 100px - var(--AppFooter));
    width: 100%;
    margin: auto;
    flex-direction: column;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    overflow: auto;

  div.Header {
    font-size: 28px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--mainWhiteOrBlack);
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    width: 50%;
    background-color: var(--mainColor);
  }
  
  .FormContainer{
    margin: 10px 0 0;
    position: relative;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 18px;
    background-color: #fff;
    border-radius: 12px;
    padding: 40px 2%;
    
    .TermsLine {
      width: 100%;
      padding: 0 1%;
      text-align: center;
      div.CheckTerm{
        width: 100%;
        display: flex;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        input{
            width: 20px;
            height: 30px;
        }
        label{
            width: 20rem;
            font-size: 17px;
           
            color: var(--text-error);
            span{
                font-weight: 900;
                text-decoration: underline;
                color: var(--link-error);
                text-transform: lowercase;
            }
            span:hover{
                cursor: pointer;
            }
        }
    }
    }
    .Form {
      /* border: 1px solid red; */
      width: 100%;
      padding: 0 28%;
      z-index: 1;
      
      div.FormLine {
        width: 100%;

        div.FormLineOne {
          width: 100%;
          padding: 3px;
          
        }

        div.FormLineTwo {
          width: 50%;
          padding: 3px;
          display: inline-block;
        }

        .FormInput {
          display: inline-block;
          width: 100%;
          height: 42px;
          outline: none;
          border-radius: 6px;
          resize: none;
          overflow: auto;
          font-family: 'Heebo', sans-serif;
          padding: 2px 5px 2px 5px;
          box-shadow: 1px 1px 2px #3B3B3B7A;
          font-size: 22px;
          border: 1px solid rgb(255, 255, 255);

          &.FormInputWrong {
            border: 1px solid #eb0028;
          }
        }
        /* body[dir="rtl"] input.FormInput {
          text-align: left;
        } */
        
      }
    }
  }
  

  div.RegisterButton {
    margin-bottom: 15px;
    font-size: 30px;
    width: 570px;
    margin-top: 20px;
    text-align: center;
    padding: 8px;
    color: var(--mainWhiteOrBlack);
    border-radius: 50px;
    cursor: pointer;
    background-color: var(--mainColor);
  }
  
  div.RegisterButton:hover{
    opacity: .9;
  }

  div.ModalContainer{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 850px){
  div.container {
    height: calc((var(--vh, 1vh) * 100) - 100px);

    div.Header {
      font-size: 20px;
      font-weight: 400;
      padding-top: 20px;
      padding-bottom: 10px;
      width: auto;
      max-width: 90%;
    }
    .FormContainer::before {
      top: -10px;
      right: -10px;
      left: -10px;
      bottom: -10px;
    }
    .FormContainer{
      width: 100%;
      div.Form {
        width: 100%;
        z-index: 0;
        padding: 0 5%;

        div.FormLine {
          width: 100%;
    
          div.FormLineOne {
          }
    
          div.FormLineTwo {
          }

          .FormInput {
            font-size: 20px;
            padding: 5px;
            padding-left: 10px;
            padding-right: 10px;
          }
          /* body[dir="rtl"] input.FormInput {
            text-align: left;
          } */
        }
      }
    }
    div.RegisterButton {
      z-index: 0;
      font-size: 30px;
      width: 80vw;
      margin-top: 40px;
      padding: 4px;
    }
  }
}