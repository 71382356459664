div.Container{
    background: #D6D6D6 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 5px;
    padding: 20px 0 20px 0;
    cursor: pointer;
}


@media (max-width: 500px){
    div.Container{
        overflow: auto;
        font-size: 11px;
        margin-left: 5px;
        margin-right: 5px;
        padding: 10px 0 10px 0;
    }
}