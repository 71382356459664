.Container{
  /* border: 4px solid blue; */
  overflow: hidden;
  width: 100%;
  height: 85vh;
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: 5px;
  margin: 0 5px;
  /* height: 100%; */

     .Spinner{
      /* border: 1px solid red; */
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60vh;
    }

    .HistList {
      /* border: 5px solid pink; */
      z-index: 0;
    
      height: 85vh;
      overflow: auto;
      flex: 4 4;
      flex-flow: column nowrap;
      padding-top: 0;
      padding-bottom: 20vh;
      margin: 0;
    }
}
/* .TopDiv {
  /* border: 8px solid purple;
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 5px;
  overflow: hidden;
  height: 100%;
} */

/* .contBasket {
  /* border: 6px solid orange; 
  flex: 2 2;
  /* width: 100%;
  height: 100%;
} */
/* 
body[dir="rtl"] .contBasket {
  margin-right: 10px;
  margin-left: 0;
} */



/* .Basket {
  /* border: 3px solid green; 
  display: flex;
  height: 85vh;
  flex-flow: column nowrap;
  background-color: var(--mainWhite);
  border-radius: var(--brdRadius);
  /* overflow: auto;

  position: relative;
} */
.closeBasket {
  width: max-content;
  height: max-content;
  position: absolute;
  top:0;
  left:0;
  padding-left: 10px;
  padding-top: 5px;
  cursor: pointer;
  font-size: 25px;
}

/* @media screen and (max-width: 850px) {
  .contBasket{
    /* flex: unset;
    width: 100%;
  }
} */

/* @media screen and (max-width: 600px) {
    .TopDiv {
      display: flex;
      flex-flow: column nowrap;
    }
  } */