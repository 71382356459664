div.Container{
    text-align: center;
    margin: 0;
    padding: 0;
    position: relative;

    div.CancleXBtn{
        position: absolute;
        left: 0;
        text-align: left;
        color: #808080;
        span{
            font-size: 22px;
        }

        span:hover{
            cursor: pointer;
        }
    }
    
    div.TextContnet{
        margin-top: 5px;
        div.SadImg{

        }

        div.Title{
            span{
                font-weight: 550;
                font-size: 23px;
            }
        }

        div.RowsText{
            margin-top: 5px;
            display: flex;
            flex-direction: column;
            font-size: 20px;
        }
    }

    .BackBtn{
        display: flex;
        justify-content: center;
        margin-top: 10px;
        color: var(--mainWhiteOrBlack);

        span{
            background-color: var(--mainColor);
            border-radius: 20px;
            width: 55%;
            font-size: 20px;
            padding: 5px;
        }

        span:hover{
            cursor: pointer;
        }
    }

    .ContinueBtn{
        display: flex;
        justify-content: center;
        margin-top: 10px;
        color: var(--mainMainOrBlack);

        span{
            border: 1px solid var(--mainMainOrBlack);
            border-radius: 20px;
            width: 55%;
            font-size: 20px;
            padding: 5px;
        }

        span:hover{
            cursor: pointer;
        }
    }
}