div.Container{
    display: flex;
    width: 100%;
    justify-content: center;

    div.UnitTypes {
        background: #D9D9D9;
        border-radius: var(--brdRadius);;
        display: flex;
        padding: 3px 4px;
        margin-bottom: 10px;
    }
    
    div.UnitSel{
        width: 60px;
        height: 22px;
        font-size: 15px;
        line-height: 21px;
        border-radius: var(--brdRadius);
        border:1px solid var(--mainMainOrBlack);
        font-size: 17px;
        background-color: var(--mainMainOrBlack);
        color: #fff;
        cursor: pointer;
    }

    div.UnitNotSel{
        width: 60px;
        height: 22px;
        font-size: 15px;
        line-height: 21px;
        border-radius: var(--brdRadius);
        /* border:1px solid var(--mainMainOrBlack); */
        font-size: 17px;
        background-color: unset;
        color: #000;
        cursor: pointer;
    }

    div.Unit:hover{
        cursor: pointer;
    }
}

div.Container > * + *{
    margin-right: 5px;
}