div.ModalContainer{
    /* margin: 0;
    padding: 0;
    background: url(../../../assets/welcome_bg.png) no-repeat;
    background-size: 100%;
    background-position: bottom;
    background-color: #FFFF;
    min-height: 100%; */

    margin-top: 25px;
    height: 240px;
    display: block;
    /* flex-direction: column;
    justify-content: space-evenly;
    align-items: center; */
    /* gap: 18px; */

    h3{
        margin: 60px auto 0 auto ;
        /* margin-left: auto;
        margin-right: auto; */
        text-align: center;
        width: 60%;
        font-size: 23px;
        overflow: none;
    }

    button{
        position: absolute;
        bottom: 5px;
        left: 0;
        right: 0;
        margin: 0 auto 15px auto;
        font-family: Heebo;
        outline: none;
        width: 185px;
        height: 45px;
        border-radius: 25px;
        font-size: 18px;
        align-text: center;
        font-weight: 600;
        background-color: unset;
        color: var(--mainMainOrBlack);
        border: 1px solid var(--mainMainOrBlack);
        cursor: pointer;
    }

    h3{
        text-overflow: ellipsis;
    }
}