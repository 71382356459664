div.ModalContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* gap: 30px; */
    margin-top: 15px;
    
    .ModalTitle{
        color: #000000;
        font-size: 50px;
        font-weight: 600;
    }
    .ModalTitle::first-letter{
        text-transform: uppercase;
    }

    div.ModalContent{
        margin: 50px;
        margin-top: 0;
        font-weight: 500;
    }

    div.ModalButton{
        height: 40px;
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #000000;
        border-radius: 20px;
        margin-bottom: 20px;
        font-weight: 550;
        font-size: 20px;
        span{
            margin: 10px;
        }
    }

    div.ModalButton:hover{
        background-color: #000000;
        border: 1px solid #FFFFFF;
        color: #FFFFFF;
        cursor: pointer;
    }
}

div.ModalContainer > *{
    margin-bottom: 30px; 
}
