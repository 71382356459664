.NavArrow {
    position: absolute;
    width: 90%;
    background-color: white;
    height: 20px;
    opacity: 0.7;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 850px) {
    .NavArrow {
        width: 30px;
        height: 90%;
      }
  } 