.AppTopMenu {
  text-align: center;
  display: flex;
  flex-flow: row-reverse wrap;
  justify-content: flex-end;
  align-content: center;
  padding: 5px;
  flex: 1 1;
}

/* .Container{
  align-self: center;
}


div.menubtn {
  padding: 10px;
  background-color: transparent;
  border: 0px;
  color: var(--mainWhite);
  font-size: 24px;
  font-weight: 400;
  cursor: pointer;

  &.menubtnActive {
    text-decoration: underline var(--mainWhite);
  }
}
input.searchbox {
    background: no-repeat right;
    background-size: 20px;
    width: 200px;
    cursor: pointer;
    border: 1px solid var(--mainWhite);
    border-radius: 31px;
    color: black;
} */

/* @media screen and (max-width: 600px) {
    .AppTopMenu {
      display: flex;
      flex-flow: column-reverse wrap;
    }
  } */