.Container{
  /* border: 6px solid red; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* align-self: center; */
  /* height: 100%; */
  flex: 2 2;
  height: 100%;
  margin-inline-end: 10px;
  /* width: 100%; */
  background-color: var(--mainWhite);
  border-radius: var(--brdRadius);
  
  .CancelXBtn {
    position: relative;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    align-self: flex-end;
    display: none;
  }
}
.TopDiv {
    text-align: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    height: 100%;
    margin-top: 15px;
    padding-bottom: 10px;
    color: var(--mainColor)
}

.OrderNumber{
  font-size: calc(.2vw + 14px);
  font-weight: 600; 
}

.List {
  /* border: 6px solid red; */
  overflow: auto;
  height: 100%;
  margin-top: 35px;
  padding: 0 15px 0 15px;
  flex: 4;
  
}

.ButtonWrapper{
  /* border: 6px solid blue; */
  height: 15%;
  display: flex;
  justify-content: center;
}
.button {
  cursor: pointer;
  align-self: center;
  width: 50%;
  /* margin: 30px 0 30px 0; */
  border-radius: 20px;
  font-size: calc(.2vw + 12px);

}

@media screen and (max-width: 850px) {
  .Container{
    flex: 2 2 95%;
    .CancelXBtn {
      display: block;
    }
  }
}

/* @media screen and (max-width: 600px) {
    .TopDiv {
      display: flex;
      flex-flow: column nowrap;
    }
  } */
