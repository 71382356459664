*, *::before, *::after{
  font-family: 'Heebo', sans-serif;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  position: fixed;
  width: 100%;
  overflow: hidden;
  /* min-height: 100%;
  max-height: 100%; */
  /* background-color: #000000; */
  
  font-family: 'Heebo', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@media (max-width: 850px) {
  body, html {
    height: auto;
    overflow: auto;
  }
}