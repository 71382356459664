div.Container {
    position: relative;
    width: 100%;
    
    .FlagPlaceholder {
        position: absolute;
        left: 10px;
        top: 50%;
        /* width: 30px;
        height: 30px;
        border-radius: 10px; */
        overflow: hidden;
        transform: translateY(-50%);
      }
    body[dir="rtl"] .FlagPlaceholder &{
       text-align: right;
    }
}