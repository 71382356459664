div.ModalContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 43vh;
    position: relative;
    
    div.SpinnerIcon{
        position: absolute;
        left: 48%;
    }

    div.Content{
        margin-top: 163px;
        span{
            font-weight: 500;
        }
    }
}